<template>
  <div style="margin: 20px">
    <el-button type="success" style="margin-bottom: 10px" @click="create">Создать</el-button>
    <v-table :fetch="fetchData" name="classifiers"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getClassifiers } from '@/api/dictionaries'
export default {
  components: {
    VTable
  },
  data () {
    return {

    }
  },
  methods: {
    fetchData: (...args) => getClassifiers(...args),
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'create') })
    }
  }
}
</script>

<style scoped>

</style>
